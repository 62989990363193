.app-auth-layout {
  min-height: 100vh;
  align-items: center;
}

.app-auth-layout .container {
  display: block;
  margin: auto;
  position: relative;
}

.auth-card {
  /* padding: 20px; */
  border-width: 1px;
  border-radius: 6px;
}

.auth-card:before,
.auth-card:after {
  position:absolute;
  content:"";
  top:14px;bottom:14px;left:0;right:0;
  box-shadow:0 0 25px 3px #548E7F;
  border-radius:100px/10px;
  z-index:-1;
}

.container .auth-header {
  text-align: center;
}

.container .auth-content {
  width: 250px;
  text-align: center;
}

.auth-content .ant-row.ant-form-item {
  margin-bottom: 12px;
}

.auth-content .login-form-button {
  width: 100%;
}
