.app-dashboard-layout {
  min-height: 100vh;
}

.app-dashboard-layout .logo {
  padding: 10px;
}

.app-dashboard-layout .logo > img {
  height: 45px;
}

.app-dashboard-layout .ant-layout-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}

.app-dashboard-layout .dashboard-inner {
  margin-left: 200px;
  width: calc(100% - 200px);
  transition: all .2s ease;
}

.app-dashboard-layout .dashboard-header {
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
}


.dashboard-body .dashboard-body-header {
  margin-top: 2px;
  padding: 20px;
  background-color: #fff;
}

.dashboard-body .dashboard-body-content {
  padding: 20px;
}

.dashboard-body .dashboard-body-map {
  display: block;
  height: 100%;
}

.dashboard-body .dashboard-body-map .map-container .leaflet-container {
  height: calc(100vh - 65px);
}

.app-dashboard-layout .ant-layout-sider-collapsed + .dashboard-inner {
  margin-left: 80px;
  width: calc(100% - 80px);
}

.app-dashboard-layout .ant-layout-sider-zero-width + .dashboard-inner {
  margin-left: 0px;
  width: 100%;
}

.app-dashboard-layout i.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: all .3s,padding 0s;
  padding: 0 24px;
}

.header-dropdown-link {
  display: inline-block;
}

.header-dropdown-user {
  border: 1px solid #eee;
  top: -4px;
  position: absolute;
}

.header-dropdown-user .ant-dropdown-menu-item {
  padding: 10px 14px;
}

.header-shortcut-menu i {
  padding-right: 6px;
}

.app-dashboard-layout .dashboard-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light {
  left: 83px !important;
}

.dashboard-header .header-left {
  flex: 1 1 50%;
}

.dashboard-header .header-left>span {
  display: inline-block;
}

.dashboard-header .header-right {
  flex: 1 1 50%;
  text-align: right;
  height: 100%;
}

.dashboard-header .header-right>span {
  font-size: 16px;
  line-height: 64px;
  cursor: pointer;
  transition: all .3s,padding 0s;
  padding: 0 10px;
  display: inline-block;
  margin: 0 6px;
}

.dashboard-header .header-right i {
  font-size: 16px;
  padding: 0 8px;
}

.dashboard-header .header-right i.anticon-search {
  padding: 0;
}

.dashboard-header .header-right .header-dropdown-link {
  margin-left: 8px;
}

.header-dropdown-link .ant-avatar {
  margin-right: 4px;
}

.app-dashboard-layout .notif-badge {
  margin-top: -5px;
}

.content-wrapper {
  padding: 15px;
  background: #fff;
  border-radius: 8px;
}

.content-wrapper .content-header {
  margin-bottom: 15px;
}
.content-wrapper .content-header>span {
  font-weight: 600;
  font-size: 18px;
}

.content-wrapper .content-header button {
  float: right;
}

.sidebar-trigger-collapse {
  display: none;
}

@media (max-width: 992px) {
  /* .app-dashboard-layout .ant-layout-sider-collapsed + .dashboard-inner {
    margin-left: 0;
    width: 100%;
  }

  .app-dashboard-layout .ant-layout-sider.ant-layout-sider-collapsed {
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    flex: 0 0 0 !important;
  } */

  .app-dashboard-layout .ant-layout-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    width: 0 !important;
  }

  .app-dashboard-layout .dashboard-inner {
    margin-left: 0;
    width: 100%;
  }

  .app-dashboard-layout .ant-layout-sider {
    z-index: 1090;
  }

  .sidebar-trigger-collapse {
    display: block;
    position: fixed;
    bottom: 0;
    background-color: #222328;
    color: #fff;
    text-align: center;
    cursor: pointer;
  }

  .app-sidebar .sidebar-trigger-collapse {
    padding: 10px;
    width: 200px;
  }

  .app-sidebar.ant-layout-sider-collapsed.ant-layout-sider-zero-width .sidebar-trigger-collapse {
    width: 0;
    padding: 0;
  }

  .app-sidebar.ant-layout-sider-collapsed .sidebar-trigger-collapse i {
    display: block;
  }

  .app-sidebar.ant-layout-sider-collapsed.ant-layout-sider-zero-width .sidebar-trigger-collapse i {
    display: none;
  }

  .app-sidebar .sidebar-menu-container {
    margin-bottom: 42px;
  }
}

@media (max-width: 600px) {
  .app-dashboard-layout .dashboard-header {
    padding-left: 0;
    padding-right: 5px;
  }
  .dashboard-header .header-left {
    flex: 1 1 15%;
  }

  .header-left .shortcut-trigger {
    display: none;
  }

  .dashboard-header .header-right .header-search-wrapper,
  .dashboard-header .header-right .header-notification-wrapper {
    display: none;
  }
}
