@import url(https://fonts.googleapis.com/css?family=Exo+2:300,400,500,600);
/* Loading */
.loading-page {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3B55E6;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2:before {
  background-color: #FE5339;
}

.sk-folding-cube .sk-cube3:before {
  background-color: #4BE1AB;
}

.sk-folding-cube .sk-cube4:before {
  background-color: #F3D351;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  } 
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}

.header-notif-tab-list {
  width: 300px;
}

.header-notif-tab-list .ant-tabs-nav-wrap{
  text-align: center;
}

.app-dashboard-layout {
  min-height: 100vh;
}

.app-dashboard-layout .logo {
  padding: 10px;
}

.app-dashboard-layout .logo > img {
  height: 45px;
}

.app-dashboard-layout .ant-layout-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}

.app-dashboard-layout .dashboard-inner {
  margin-left: 200px;
  width: calc(100% - 200px);
  transition: all .2s ease;
}

.app-dashboard-layout .dashboard-header {
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
}


.dashboard-body .dashboard-body-header {
  margin-top: 2px;
  padding: 20px;
  background-color: #fff;
}

.dashboard-body .dashboard-body-content {
  padding: 20px;
}

.dashboard-body .dashboard-body-map {
  display: block;
  height: 100%;
}

.dashboard-body .dashboard-body-map .map-container .leaflet-container {
  height: calc(100vh - 65px);
}

.app-dashboard-layout .ant-layout-sider-collapsed + .dashboard-inner {
  margin-left: 80px;
  width: calc(100% - 80px);
}

.app-dashboard-layout .ant-layout-sider-zero-width + .dashboard-inner {
  margin-left: 0px;
  width: 100%;
}

.app-dashboard-layout i.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: all .3s,padding 0s;
  padding: 0 24px;
}

.header-dropdown-link {
  display: inline-block;
}

.header-dropdown-user {
  border: 1px solid #eee;
  top: -4px;
  position: absolute;
}

.header-dropdown-user .ant-dropdown-menu-item {
  padding: 10px 14px;
}

.header-shortcut-menu i {
  padding-right: 6px;
}

.app-dashboard-layout .dashboard-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light {
  left: 83px !important;
}

.dashboard-header .header-left {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
}

.dashboard-header .header-left>span {
  display: inline-block;
}

.dashboard-header .header-right {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
  text-align: right;
  height: 100%;
}

.dashboard-header .header-right>span {
  font-size: 16px;
  line-height: 64px;
  cursor: pointer;
  transition: all .3s,padding 0s;
  padding: 0 10px;
  display: inline-block;
  margin: 0 6px;
}

.dashboard-header .header-right i {
  font-size: 16px;
  padding: 0 8px;
}

.dashboard-header .header-right i.anticon-search {
  padding: 0;
}

.dashboard-header .header-right .header-dropdown-link {
  margin-left: 8px;
}

.header-dropdown-link .ant-avatar {
  margin-right: 4px;
}

.app-dashboard-layout .notif-badge {
  margin-top: -5px;
}

.content-wrapper {
  padding: 15px;
  background: #fff;
  border-radius: 8px;
}

.content-wrapper .content-header {
  margin-bottom: 15px;
}
.content-wrapper .content-header>span {
  font-weight: 600;
  font-size: 18px;
}

.content-wrapper .content-header button {
  float: right;
}

.sidebar-trigger-collapse {
  display: none;
}

@media (max-width: 992px) {
  /* .app-dashboard-layout .ant-layout-sider-collapsed + .dashboard-inner {
    margin-left: 0;
    width: 100%;
  }

  .app-dashboard-layout .ant-layout-sider.ant-layout-sider-collapsed {
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    flex: 0 0 0 !important;
  } */

  .app-dashboard-layout .ant-layout-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    width: 0 !important;
  }

  .app-dashboard-layout .dashboard-inner {
    margin-left: 0;
    width: 100%;
  }

  .app-dashboard-layout .ant-layout-sider {
    z-index: 1090;
  }

  .sidebar-trigger-collapse {
    display: block;
    position: fixed;
    bottom: 0;
    background-color: #222328;
    color: #fff;
    text-align: center;
    cursor: pointer;
  }

  .app-sidebar .sidebar-trigger-collapse {
    padding: 10px;
    width: 200px;
  }

  .app-sidebar.ant-layout-sider-collapsed.ant-layout-sider-zero-width .sidebar-trigger-collapse {
    width: 0;
    padding: 0;
  }

  .app-sidebar.ant-layout-sider-collapsed .sidebar-trigger-collapse i {
    display: block;
  }

  .app-sidebar.ant-layout-sider-collapsed.ant-layout-sider-zero-width .sidebar-trigger-collapse i {
    display: none;
  }

  .app-sidebar .sidebar-menu-container {
    margin-bottom: 42px;
  }
}

@media (max-width: 600px) {
  .app-dashboard-layout .dashboard-header {
    padding-left: 0;
    padding-right: 5px;
  }
  .dashboard-header .header-left {
    -webkit-flex: 1 1 15%;
            flex: 1 1 15%;
  }

  .header-left .shortcut-trigger {
    display: none;
  }

  .dashboard-header .header-right .header-search-wrapper,
  .dashboard-header .header-right .header-notification-wrapper {
    display: none;
  }
}

.app-auth-layout {
  min-height: 100vh;
  -webkit-align-items: center;
          align-items: center;
}

.app-auth-layout .container {
  display: block;
  margin: auto;
  position: relative;
}

.auth-card {
  /* padding: 20px; */
  border-width: 1px;
  border-radius: 6px;
}

.auth-card:before,
.auth-card:after {
  position:absolute;
  content:"";
  top:14px;bottom:14px;left:0;right:0;
  box-shadow:0 0 25px 3px #548E7F;
  border-radius:100px/10px;
  z-index:-1;
}

.container .auth-header {
  text-align: center;
}

.container .auth-content {
  width: 250px;
  text-align: center;
}

.auth-content .ant-row.ant-form-item {
  margin-bottom: 12px;
}

.auth-content .login-form-button {
  width: 100%;
}

/* Global */

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.no-margin {
  margin: 0px;
}

.text-danger {
  color: #FE5339;
}

.float-right {
  float: right;
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center;
}

.no-padding {
  padding: 0 !important;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* collapse */
.ant-collapse.filter-container .ant-collapse-header,
.ant-collapse.filter-container .ant-collapse-content,
.ant-collapse.filter-container .ant-collapse-content-box {
  padding: 0 !important;
}

.ant-collapse.filter-container>.ant-collapse-item {
  border: 0 !important;
}

/* avatar */
.avatar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
}

.avatar-container>span {
  margin-right: 6px;
}

.avatar-container>span:last-child {
  margin-right: 0;
}

.img-avatar {
  width: 65px;
  height: 65px;
}

.img-rounded {
  border-radius: 50%;
}

/* List */
.fleet-details-content .ant-list-item-content>div {
  margin-bottom: 10px;
}

.fleet-details-content .ant-list-item-content>div:last-child {
  margin-bottom: 0;
}

/* Column */
.ant-col-xs-24 {
  margin-bottom: 12px;
}

/* Button */
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fd381a;
  background-color: #fff;
  border-color: #fd381a;
}

.ant-btn-info {
  color: #fff;
  background-color: #51DDE9;
  border-color: #51DDE9;
}

.ant-btn-info:hover, .ant-btn-info:focus {
  color: #35d7e5;
  background-color: #fff;
  border-color: #35d7e5;
}

/* Sidebar */
.app-sidebar {
  border-right: 1px solid#dcdcdc;
}

/* Order */
.order-overview {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.order-type-content {
  border-top: 1px solid #e8e8e8;
  height: 200px;
}

.order-status-widgets {
  width: 100%;
  position: relative;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
}

.order-status-widgets h3 {
  padding: 5px 5px 10px 5px;
  border-bottom: 1px solid #e8e8e8;
}

.order-status-widgets .order-status-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 5px auto 10px auto;
  padding-top: 10px;
  position: relative;
}

.order-status-widgets .order-status-content .order-status-widget {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: calc(22% - 5px);
          flex-basis: calc(22% - 5px);
  box-sizing: border-box;
  position: relative;
  padding: 6px 12px;
  margin: 5px;
  border: solid .5em transparent;
  color: #fff;
}


.dashboard-body-content .ant-card {
  border-radius: 8px;
}

.dashboard-body-content .ant-card .ant-card-head {
  border-radius: 8px;
}

.order-form {
  max-width: 660px;
}

.order-form-page {
  padding: 20px;
  border-radius: 8px;
  background: #fff;
}

.order-form-header {
  max-width: 800px;
  margin: 20px auto;
}

.order-form-content {
  margin: 20px auto;
}

.order-form .order-form-content .ant-calendar-picker {
  width: 100%;
}

.content-wrapper .ant-calendar-picker {
  width: 100%;
}

.order-form-map .leaflet-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.order-form-actions {
  margin: 10px auto;
  text-align: center;
}

.order-form-actions button {
  margin-right: 8px;
}

.order-form-actions button:last-child {
  margin-right: 0;
}

/* Order-list */
.card-order-list .ant-card-head {
  border-bottom-color: transparent;
}

.card-order-list .ant-card-body {
  padding-top: 0;
}

.card-order-list .ant-card-head-wrapper {
  -webkit-align-items: center;
          align-items: center;
}

.card-order-list .ant-card-head-wrapper .ant-card-head-title {
  font-size: 18px;
}

.card-order-list .ant-tabs-bar {
  margin-bottom: 0;
}

.card-fleet-overview .ant-list-item-meta {
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 5 1;
          flex: 5 1;
}

/* Order-single */
.order-details {
  display: block;
  position: relative;
}

.order-details .order-detail {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 6px 0;
}

.order-detail span:first-child {
  -webkit-flex: 1 1 25%;
          flex: 1 1 25%;
}

.order-detail span:last-child {
  -webkit-flex: 1 1 75%;
          flex: 1 1 75%;
}

.card-order-detail .ant-card-actions>li {
  margin: 0;
}

.card-order-detail .ant-card-actions>li>span {
  display: block;
}

.card-order-detail .ant-card-actions>li>span>button,
.card-order-detail .ant-card-actions>li>span>span,
.card-order-detail .ant-card-actions>li>span>span>button {
  display: block !important;
  width: 100%;
}

.card-broadcast {
  background-color: transparent;
  border-color: transparent;
}

.card-broadcast .ant-card-body>div {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.card-broadcast .ant-card-body>div>.ant-card-grid {
  position: relative;
  background-color: #fff;
}

/* Timeline */
.ant-timeline {
  padding-top: 8px !important;
}

.ant-timeline p {
  margin-bottom: 6px;
}

.ant-timeline p.timeline-route-detail {
  display: table;
  width: 100%;
}

.ant-timeline p.timeline-route-detail>span {
  display: table-cell;
}

.ant-timeline p.timeline-route-detail>span:first-child {
  width: 50px;
}

.ant-timeline p.timeline-route-detail>span:last-child {
  width: calc(100% - 50px);
}

.order-detail .ant-timeline-item-last {
  padding-bottom: 0;
}
/* Pagination */
.card-pagination .ant-pagination-total-text {
  display: block;
}

/* Table */
.ant-table-row td,
.ant-table-thead > tr > th {
  word-break: break-word !important;
}

.ant-table-thead>tr>th {
  text-align: center !important;
  vertical-align: middle;
}

.table-action-list {
  display: block;
  width: 50px;
}

.table-action-list.action-row {
  width: auto;
}

.ant-table-fixed.table-fleet-report-form {
  padding: 0;
}

.table-fleet-report-form .fr-row-name {
  width: 150px;
  min-width: 150px;
}

.table-fleet-report-form .fr-row-condition {
  width: 120px;
  min-width: 120px;
}

.table-fleet-report-form .fr-row-action {
  width: 130px;
  min-width: 130px;
}

.table-fleet-report-form .fr-row-action-btn {
  width: 60px;
  min-width: 60px;
}

.table-fleet-report-form .fr-row-info {
  width: 150px;
  min-width: 150px;
}

.table-action-list>button:not(:last-child) {
  margin-bottom: 5px;
  margin-right: 5px;
}

.order-table .ant-timeline>.ant-timeline-item {
  padding-bottom: 0;
}

.order-table .ant-timeline>.ant-timeline-item p {
  margin-bottom: 8px;
}

.fleet-table-details span {
  font-weight: 600;
  font-size: 14px;
}

.fleet-table-details p {
  margin-bottom: 6px;
  font-size: 15px;
}

/* Form */
.ant-form.no-margin-form-item .ant-form-item {
  margin-bottom: 0;
}

/* Form Radio */
.vertical-radio {
  width: 100%;
}

.vertical-radio:not(:first-child) {
  display: block;
  height: 30px;
  line-height: 30px;
}

.vertical-radio input {
  display: block;
  margin-top: 12px;
  margin-left: 24px;
  width: 250px;
  margin-bottom: 0;
}

/* Select */
.select-with-image {
  display: table;
  width: 100%;
}

.select-with-image>span {
  display: table-cell;
}

.select-with-image .select-image {
  width: 25%;
  text-align: center;
}

.select-with-image .select-text {
  width: 75%;
  padding-left: 12px;
}

.select-with-image .select-image img {
  height: 20px;
}

.select-full-width .ant-select {
  width: 100%;
}

/* Upload */
.hide-dragger .ant-upload.ant-upload-drag {
  width: 0;
  padding: 0;
}

.upload-inside-modal .ant-upload-list-item {
  margin-top: 0;
}

/* Widget */
.widget {
  display: block;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.map-container {
  display: block;
  position: relative;
  z-index: 0;
}

.map-container .leaflet-container {
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.map-user-container {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.map-user-container .leaflet-container {
  display: block;
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 0;
}

/* Search */
.search-page .ant-card-body {
  padding-top: 0;
  padding-bottom: 10px;
}


/* Chat container */
.chat-container {
  display: block;
  position: fixed;
  bottom: 0;
  right: 15px;
  border-radius: 0;
}

.chat-instance {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  margin-right: 2px;
  width: 300px;
  height: auto;
}

.chat-container .chat-instance:last-child {
  margin-right: 0;
}

.chat-instance .ant-collapse-content {
  padding: 0;
}

.chat-instance .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}

.chat-instance-container {
  display: block;
  position: relative;
  max-height: 300px;
}

.chat-instance-container .chat-list {
  display: block;
  position: relative;
  height: 250px;
  width: 100%;
}

.chat-instance-container .chat-list .chat-list-scrollable {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.chat-instance .chat-close {
  padding-top: 3px;
  padding-right: 10px;
  font-size: 18px;
}

.chat-instance .chat-unread {
  background-color: #3B55E6;
}

.chat-instance .chat-unread .ant-collapse-header span {
  color: #fff;
}

.chat-instance .chat-input {
  display: block;
  position: relative;
  bottom: 0;
  border-top: 1px solid #e8e8e8;
}

.chat-instance .chat-input .ant-input {
  border: none;
  border-color: transparent;
  border-radius: 0;
  resize: none;
  padding: 10px 16px;
}

.chat-instance .chat-input .ant-input:focus {
  outline: none;
  box-shadow: none;
  border-color: transparent;
}

.chat-instance .chat-input .ant-btn {
  width: 100%;
  border-radius: 0;
}

.chat-list-scrollable .chat-message {
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin-bottom: 12px;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.chat-message .chat-message-user {
  -webkit-flex-basis: 20px;
          flex-basis: 20px;
  margin-top: 6px;
  margin-right: 6px;
}

.chat-message .chat-message-detail {
  -webkit-flex-basis: calc(100% - 20px);
          flex-basis: calc(100% - 20px);
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  padding: 8px;
}

.chat-message-detail p {
  font-size: 12px;
  margin-top: 2px;
  text-align: right;
  margin-bottom: 0;
  font-style: italic;
}

/* Ribbon */
.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#3498DB 0%, #3498DB 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #3498DB;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #3498DB;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #3498DB;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #3498DB;
}

@media (max-width: 1300px) {
  .order-status-widgets .order-status-content .order-status-widget {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    -webkit-flex-basis: calc(32% - 5px);
            flex-basis: calc(32% - 5px);
  }
}

@media (max-width: 992px) {
  .order-status-widgets .order-status-content .order-status-widget {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    -webkit-flex-basis: calc(49% - 5px);
            flex-basis: calc(49% - 5px);
  }
}

@media (max-width: 768px) {
  .order-status-widgets .order-status-content .order-status-widget {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    -webkit-flex-basis: calc(100% - 5px);
            flex-basis: calc(100% - 5px);
  }

  .ant-table-fixed.table-fleet-report-form {
    width: 670px;
    min-width: 670px;
  }
}


@media (max-width: 500px) {
  .content-wrapper .float-right {
    display: block;
    float: none;
    padding-top: 12px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .ant-table-fixed.table-fleet-report-form {
    width: 670px;
    min-width: 670px;
  }
}

.spincomponent {
  text-align: center;
  background: rgba(0,0,0,0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
